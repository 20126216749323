@import './mixins/title-text';

.padded-info {
	background: $gray10;
	font-weight: 300;
	font-size: 1rem;
	padding: 0.5rem;
	border-radius: 0.5rem;
}

.required {
	&::after {
		color: $red10 !important;
		content: '*';
	}
}
