.page-container {
	height: 100%;
	width: 100%;
	padding: 0 6.4rem;
	padding-bottom: 2rem;
	@include flexbox(row, center, center);
	&.column {
		@include flexbox(column, flex-start, flex-start);
	}
	@media all and (max-width: $breakpoint-1) {
		padding: 0 1.4rem;
	}
	.page-wrapper {
		display: grid;
		width: 100%;
		margin-top: 2rem;
		grid-template-columns: 2fr 1fr;
		@media all and (max-width: $breakpoint-1) {
			@include flexbox(column, flex-start, stretch);
		}
	}
	position: relative;
	background: $aqua15;
}

.comp-wrapper {
	width: 100%;
	padding: 1.7rem 2rem;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
	&.plan-metrics {
		padding: 0;
		padding-top: 1.5rem;
	}
	.sub-comp {
		padding: 1.5rem 2rem;
	}
	background: $white;
	border-radius: 1.2rem;
	margin-bottom: 1rem;
	.sub-section {
		margin-top: 2rem;
	}
}

.right-side-section {
	padding: 2rem;
	padding-right: 0;
	padding-top: 0;
	margin-top: 0;
	height: 100%;
	@include flexbox(column, flex-start, stretch);
	@media all and (max-width: $breakpoint-1) {
		padding: 0.5rem;
		padding-top: 0;
	}
}

@media all and (max-width: 700px) {
	.btn-container {
		.btn {
			margin-bottom: 0.8rem;
		}
	}
}
