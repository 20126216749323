// variables
@import './variables/colors';
@import './variables/fonts';
@import './variables/variables';

// mixins
@import './mixins/flexbox';

// layouts
@import './layouts';

// typography
@import './typography';

// pages
@import './pages/landing-page';
@import './pages/payment-success';
@import './pages/thank-you-page';
@import './pages/insurer-page';

// containers
@import './components/containers/carousel';
@import './components/containers/app-action-variants';

// atoms
@import './components/atoms/splash-screen';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Inter';
}

.app-route-components {
	position: relative;
	width: 100%;
	height: 100%;
}

html {
	scroll-behavior: smooth;
}

body {
	background: $white;
}
