.app-action-container {
	@include flexbox(row, stretch, center);
	margin: 1.1rem 0;
	.app-action-button-container {
		@include flexbox(column, space-between, stretch);
		width: 100%;
		height: 100%;
		.app-action-btn {
			cursor: pointer;
			width: 100%;
			font: 0.9rem 'Inter';
			font-weight: 600;
			margin: 0;
			color: $gray80;
			.info {
				padding: 0 1.5rem;
				@include flexbox(row, flex-end, center);
				@media all and (max-width: $breakpoint-1) {
					padding: 0;
					@include flexbox(row, center, center);
					font-size: 0.8rem;
				}
				@media all and (max-width: $breakpoint-3) {
					font-size: 0.7rem;
				}
				.img-container {
					height: 2.5rem;
					width: 2.5rem;
					border-radius: 50%;
					background: $gray40;
					padding: 0.7rem;
					margin-right: 0.7rem;
					@include flexbox(row, center, center);
					@media all and (max-width: $breakpoint-1) {
						height: 2rem;
						width: 2rem;
						padding: 0.5rem;
					}
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.rule {
			margin-top: 1rem;
			justify-self: stretch;
			height: 0.3rem;
			width: 100%;
			background: $gray10;
			@media all and (max-width: $breakpoint-1) {
				height: 0.2rem;
			}
			@include flexbox(row, flex-end, stretch);
			.tab {
				height: 100%;
				width: 60%;
				background: $gray10;
				@media all and (max-width: $breakpoint-1) {
					width: 100%;
				}
			}
		}
		&:nth-of-type(even) .info,
		&:nth-of-type(even) .rule {
			justify-content: flex-start;
			@media all and (max-width: $breakpoint-1) {
				justify-content: center;
			}
		}
		&.active {
			.tab {
				background: $loopGreen10;
			}
			.info {
				color: $loopGreen10;
				.img-container {
					background: rgba(124, 185, 178, 0.15);
				}
			}
		}
	}
}
