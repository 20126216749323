.bg-container {
	justify-self: stretch;
	position: relative;
	max-height: 90vh;
	overflow: hidden;
	width: 100%;
	margin: 0;
	padding: 0;
	background: url('../../images/landing_page/landing-bg.svg') center center
		no-repeat;
	background-size: cover;
	// https://www.freecodecamp.org/news/css-background-image-with-html-example-code/
	@include flexbox(row, center, center);
	@media all and (max-width: $breakpoint-1) {
		background: url('../../images/landing_page/mobile-landing-bg.svg') top
			center no-repeat;
		background-size: cover;
		padding: 0.4rem;
		max-height: unset;
	}
	.bg-wrapper {
		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow-y: scroll;
		overflow-x: hidden;
		margin-top: 0rem;
		padding-top: 2rem;
		width: 68%;
		@media all and (max-width: $breakpoint-1) {
			width: 95%;
			padding-top: 3rem;
		}
		height: 90vh !important;
		@include flexbox(column, flex-start, stretch);
		.page-title {
			@include flexbox(row, center, center);
			@media all and (max-width: $breakpoint-1) {
				@include flexbox(row, flex-start, center);
				img {
					height: 140%;
				}
			}
		}
	}
	.form-container {
		margin-top: 2rem;
		border-radius: 1.1rem;
		background: $white;
		box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
		padding: 1.5rem 6rem 2.5rem 6rem;
		@media all and (max-width: $breakpoint-1) {
			padding: 1rem 1.2rem;
			border-radius: 0.5rem;
			margin-top: 2.5rem;
		}
		.title {
			width: 100%;
			text-align: center;
			font: 1.3rem 'Inter';
			color: $gray100;
			font-weight: 700;
			margin: 0.8rem 0 1.4rem 0;
			@media all and (max-width: $breakpoint-1) {
				font-size: 1.1rem;
				margin-bottom: 2rem;
			}
		}
		form {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-gap: 1rem;
			grid-column-gap: 1.6rem;
			@media all and (max-width: $breakpoint-1) {
				@include flexbox(column, flex-start, stretch);
			}
		}
	}
	.submit-btn-container {
		@include flexbox(row, center, center);
		.primary-button {
			border-radius: 2rem;
			padding: 0.8rem 7rem;
			font-size: 1.2rem;
		}
		margin: 1.2rem 0;
	}
	.custom-requirements {
		@include flexbox(row, center, center);
		font-size: 1rem;
		margin: 1rem 0;
		font-weight: 600;
		color: $gray100;
		@media all and (max-width: $breakpoint-2) {
			font-size: 0.9rem;
		}
		.contact-us {
			color: $loopGreen10;
			color: pointer;
			text-decoration: none;
		}
	}
	.footer {
		background: transparent;
		@include flexbox(row, space-between, center);
		margin: 2.3rem 0;
		color: $white;
		.copyright {
			font-family: 'Work Sans';
			font-weight: 200;
			letter-spacing: -1px;
			font-size: 1.3rem;
			@media all and (max-width: $breakpoint-1) {
				font-weight: 500;
				margin-bottom: 1rem;
			}
		}
		@media all and (max-width: $breakpoint-1) {
			@include flexbox(column, flex-start, flex-start);
		}
		.social-icons {
			@include flexbox(row, flex-end, center);
			.icon {
				text-decoration: none;
				cursor: pointer;
				transition: 0.2s ease-in-out;
				&:hover {
					transform: scale(1.05);
				}
				margin-right: 1.2rem;
				img {
					height: 1.7rem;
				}
			}
		}
	}
}
