$aqua10: #e5e5e5;
$aqua20: #f5f9f9;
$aqua15: #f8f9fa;
$aqua30: #d4e3eb;

$olive20: #adc2b7;
$olive10: #e6eff0;

$gray5: #f8f8fc;
$gray40: #f8fbfa;
$gray25: #f7f8fa;
$gray10: #f1f3f5;
$gray20: #dddddd;
$gray30: #d1d1d1;
$gray50: #d6d6d6;
$gray80: #667985;
$gray100: #3e4a59;

$red10: #fb4e4e;
$red20: #cb3c3a;

$white: #fff;

$cobalt20: #d4e3eb;
$cobalt10: #f2f7fa;

$loopGreen10: #1bb4a4;
$loopGreen20: #066066;

$linkBlue: #0075ff;
$activeBlue: #009cc3;

$chrome10: rgba(255, 189, 20, 0.05);
