// $offset: 3rem;

.carousel.carousel-slider {
	margin-top: 3rem;
	display: grid;
	grid-template-columns: 10% 80% 10%;
	@media all and (max-width: $breakpoint-1) {
		margin: 1rem 0;
	}
}

.slider-wrapper.axis-horizontal {
	// padding-top: calc($offset - 0.4rem);
	padding-top: 2.6rem;
	grid-area: 2/2/3/3;
	.slide {
		display: flex !important;
		justify-content: start !important;
		align-items: start !important;
		img {
			max-height: 35vh;
		}
	}
	@media all and (max-width: $breakpoint-1) {
		padding-top: 0.5rem;
	}
}

.testimonial-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	.avatar {
		height: 3rem;
		width: 3rem !important;
		margin-bottom: 2rem;
	}
}

.arrow-container {
	display: flex;
	padding-top: 2rem;
	padding-left: 0 !important;
	cursor: pointer;
	&:first-of-type {
		justify-content: flex-start;
		grid-area: 2/1/3/2;
	}
	&:last-of-type {
		justify-content: flex-end;
		grid-area: 2/3/3/4;
	}
	.carousel-arrow {
		height: 2rem;
		width: 2.1rem;
		cursor: pointer;
	}
}

.carousel-bottom {
	@media all and (max-width: $breakpoint-1) {
		display: none;
	}
}

.control-dots {
	position: static !important;
	grid-area: 1/2/2/3;
	// margin-bottom: 2rem !important;
	display: flex !important;
	.carousel-indicator {
		margin-right: 0.6rem;
		height: 1.05rem;
		width: 1.05rem;
		border-radius: 50%;
		background: $olive20;
		&.inactive {
			opacity: 0.5;
		}
		@media all and (max-width: $breakpoint-1) {
			height: 0.7rem;
			width: 0.7rem;
		}
		cursor: pointer;
	}
	@media all and (max-width: $breakpoint-1) {
		margin-bottom: 1rem !important;
	}
}
