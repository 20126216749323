.insurer-page-row {
	@include flexbox(row, center, flex-start);
}

.insurers-container {
	padding: 0 !important;
	background: yellow;
	@include flexbox(column, flex-start, stretch);
	.insurer-details-wrapper {
		width: 100%;
		@include flexbox(row, space-between, flex-start);
		.insurer-details {
			@include flexbox(row, flex-start, flex-start);
			margin: 2rem 0 1.7rem 0;
			.img-container {
				img {
					width: 4.3rem; /* width of container */
					height: 4.3rem; /* height of container */
					border-radius: 50%;
					object-fit: cover;
				}
			}
			.details-container {
				margin-left: 2rem;
				@include flexbox(column, flex-start, flex-start);
				font-weight: 300;
				font-size: 1.3rem;
				color: $gray100;
				.title {
					font-weight: 500;
				}
				.desc {
					font-size: 1rem;
					opacity: 0.5;
				}
				a {
					text-decoration: none;
					color: #0075ff;
					font-size: 0.9rem;
				}
			}
		}
	}
}

.insurer-plan {
	background: $white;
	@include flexbox(column, stretch, stretch);
	border-radius: 1.4rem;
	padding: 1.6rem;
	padding-left: 2rem;
	@media all and (max-width: $breakpoint-2) {
		padding: 0.9rem;
		padding-left: 0.9rem;
	}
	.insurer-benefits-title {
		@include flexbox(row, space-between, center);
		.t-n-c {
			font-size: 0.85rem;
			color: $loopGreen10;
			text-decoration: none;
		}
	}
	.insurer-plan-coverage {
		padding-left: 1rem;
		.title {
			@include titleText(1.2rem, 0, 1.5rem);
		}
	}

	.insurer-plan-benefits {
		margin: 1rem 0 0 0;
		.plan-benefit {
			width: 100%;
			padding: 1rem 0.8rem;
			padding-left: 0;
			@include flexbox(row, stretch, stretch);
			.details {
				width: 100%;
				@include flexbox(row, space-between, center);
				@media all and (max-width: $breakpoint-1) {
					@include flexbox(column, flex-start, stretch);
				}
				aside {
					width: 100%;
					@include flexbox(row, flex-start, center);
					.img-container {
						padding: 1rem;
						border-radius: 50%;
						background: $gray25;
						margin-right: 1.4rem;
						img {
							width: 3.5rem; /* width of container */
							height: 3.5rem; /* height of container */
							border-radius: 50%;
							object-fit: cover;
						}
					}
					.info-container {
						color: $gray100;
						margin-left: 1rem;
						.title {
							font-weight: 500;
							font-size: 1.3rem;
							margin-bottom: 0.4rem;
						}
						.desc {
							font-weight: 300;
							font-size: 1rem;
							opacity: 0.7;
							line-height: 1.1rem;
						}
					}
				}
			}
			.value-container {
				flex-basis: 30%;
				@include flexbox(row, flex-end, center);
				font-size: 1.2rem;
				font-weight: 600;
				text-align: right;
				color: $gray100;
			}
			.discount-container {
				flex-basis: 40%;
				@include flexbox(column, center, flex-end);
				font-size: 1.2rem;
				text-decoration: line-through;
				font-weight: 500;
				.cancelled-figure {
					color: $red10 !important;
				}
				color: $gray100;
				img {
					margin-top: 0.7rem;
					height: 2.3rem;
				}
			}
			&:not(:last-of-type) {
				margin-bottom: 1.2rem;
			}
			&.highlighted {
				border-radius: 0.9rem;
				background: $chrome10;
				.details aside .img-container {
					background: transparent;
					img {
						height: 4rem;
						width: 4rem;
					}
				}
			}
		}
	}

	.insurer-plan-benefits-alt {
		display: grid;
		margin-top: 1.2rem;
		grid-template-columns: 1fr 1fr;
		@media all and (max-width: $breakpoint-0) {
			@include flexbox(column, flex-start, stretch);
		}
	}
	.other-details {
		margin-top: 1rem;
		column-gap: 0.5rem;
		@include flexbox(row, stretch, stretch);
		@media all and (max-width: $breakpoint-0) {
			@include flexbox(column, flex-start, stretch);
			row-gap: 1.1rem;
		}
	}
}
