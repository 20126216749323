.page-container.thank-you-page {
	height: 90vh;
	.thanks-modal {
		width: 55%;
		padding: 3rem 7.2rem 2.5rem 7.2rem;
		text-align: center;
		@media all and (max-width: 1140px) {
			width: 90%;
		}
		@media all and (max-width: 720px) {
			padding: 3rem 3.2rem 2.5rem 3.2rem;
			width: 97%;
		}
		@media all and (max-width: $breakpoint-2) {
			padding: 1.4rem;
			width: 97%;
		}
		background: $white;
		border-radius: 1.4rem;
		color: $gray100;
		@include flexbox(column, start, center);
		.img-container {
			img {
				height: 9rem;
			}
		}
		.title {
			margin: 1.1rem 0;
			font-size: 1.4rem;
			font-weight: 600;
		}
		.subtitle {
			font-weight: 400;
			font-size: 1.2rem;
		}
		.rule {
			width: 100%;
			height: 2px;
			background: $gray100;
			margin: 1.1rem 0;
			opacity: 0.5;
		}
		.text {
			font-weight: 400;
			font-size: 1rem;
			margin-bottom: 1rem;
		}
		.contact-links {
			@include flexbox(column, start, stretch);
			width: 100%;
			.contact-link {
				&:not(:last-child) {
					margin-bottom: 1rem;
				}
				font-size: 1rem;
				@media all and (max-width: $breakpoint-2) {
					font-size: 0.7rem;
				}
				border-radius: 0.6rem;
				padding: 1rem;
				background: $cobalt10;
				@include flexbox(row, space-between, center);
				.name {
					@include flexbox(row, flex-start, center);
					color: $loopGreen20;
					img {
						height: 1.2rem;
						margin-right: 0.5rem;
					}
				}
				a {
					color: $gray100;
					font-weight: 600;
					text-decoration: none;
				}
			}
		}
	}
}
