.payment-success-container {
    margin-top: 40px;
    text-align:center;
    margin-bottom:30px;
}
.payment-success-container .doctor-card-name{
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    color: #3E4A59;
}
.payment-success-container .doctor-card-detail {
    line-height: 28px;
    text-align: center;
    color: #3E4A59;
}
.payment-success-container .doctor-card-detail a img{
    height: 10px;;
}
.payment-success-container .doctor-card{
    margin-bottom:30px;
}
.payment-success-container .note{
    color: #3E4A59;
    opacity: 0.7;
    font-size: 13px;
}
.payment-success-container .doctor-card-details{
    padding:10px;
}