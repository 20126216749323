.splash-screen {
	width: 100%;
	font-family: 'Inter';
	color: $gray100;
	height: 90vh;
	@include flexbox(column, center, center);
	img {
		height: 3rem;
		margin-bottom: 2rem;
	}
	font-size: 1rem;
}
